var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._b({staticClass:"item-container",attrs:{"tag":"div","list":_vm.list,"value":_vm.value},on:{"input":_vm.emitter}},'div',_vm.dragOptions,false),_vm._l((_vm.realValue),function(el,index){return _c('div',{key:el._id + index,staticClass:"item-group"},[_c('BaseCard',{attrs:{"padding":"0"}},[_c('CollapsibleContentLight',{key:_vm.loopKey + el._id,attrs:{"title":_vm.getTitle(el),"collapseId":el._id,"button-bg-color":("" + (_vm.colorsHtml.background)),"button-padding":'7px 15px',"content-padding":'15px',"is-open":_vm.openItems.includes(el._id),"toggle-open-item":_vm.openItemsFn}},[_c('div',{staticClass:"item-content"},[_c('FormulateInput',{staticClass:"product-create__select",attrs:{"type":"dropDown","name":"classification","validation":"required","activeInput":true,"parentBlockId":el._id,"disabled":_vm.disabled ? _vm.disabled : _vm.dropDownObject.disabled,"dataArray":_vm.dropDownObject.data,"getList":_vm.dropDownObject.getList,"title":'Select page or type url',"selected":el.url,"searchAction":_vm.dropDownObject.dropdownSearch},on:{"dropdown-item-changed":_vm.dropDownObject.dropdownChanged},model:{value:(el.url),callback:function ($$v) {_vm.$set(el, "url", $$v)},expression:"el.url"}}),_c('FormulateInput',{class:Object.assign(
                { 'customize-label': true, active: el.title },
                _vm.editableClass
              ),attrs:{"type":"text","name":"text","label":"Text","error-behavior":"live","disabled":_vm.disabled},on:{"focus":_vm.onFocus,"blur":_vm.onBlur},model:{value:(el.title),callback:function ($$v) {_vm.$set(el, "title", $$v)},expression:"el.title"}}),_c('FormulateInput',{class:Object.assign(
                { 'customize-label': true, active: el.description },
                _vm.editableClass
              ),attrs:{"type":"text","name":"text","label":"Description","disabled":_vm.disabled,"error-behavior":"live"},on:{"focus":_vm.onFocus,"blur":_vm.onBlur},model:{value:(el.description),callback:function ($$v) {_vm.$set(el, "description", $$v)},expression:"el.description"}}),_c('FormulateInput',{class:Object.assign(
                {
                  'customize-label': true,
                  'additional-flag': true,
                  active: el.mobile_tab,
                },
                _vm.editableClass
              ),attrs:{"type":"checkbox","name":"text","label":"Additional flag","disabled":_vm.disabled,"error-behavior":"live"},on:{"focus":_vm.onFocus,"blur":_vm.onBlur},model:{value:(el.mobile_tab),callback:function ($$v) {_vm.$set(el, "mobile_tab", $$v)},expression:"el.mobile_tab"}}),_c('ImageInput',{attrs:{"label":'Image',"value":el.image,"editable":!_vm.disabled,"url":typeof el.image === 'string' ? el.image : '',"name":el._id},on:{"image-changed":function($event){return _vm.getFile($event, el._id)}}}),(!_vm.disabled)?_c('Button',{staticClass:"ml-auto mr-0 d-block item-group__btn",attrs:{"variant":"danger","label":"Delete","bg-color":("" + (_vm.colorsHtml.danger)),"font-weight":"700"},on:{"click":function($event){return _vm.deleteAction(el._id, _vm.realValue)}}}):_vm._e()],1)])],1),_c('NestedDraggable',{key:_vm.loopKey,staticClass:"item-sub",attrs:{"list":el.children,"delete-action":_vm.deleteAction,"drop-down-object":_vm.dropDownObject,"loop-key":_vm.loopKey,"open-items":_vm.openItems,"open-items-fn":_vm.openItemsFn,"disabled":_vm.disabled}})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }