<template>
  <div
    v-bind="dragOptions"
    tag="div"
    class="item-container"
    :list="list"
    :value="value"
    @input="emitter"
  >
    <div
      class="item-group"
      :key="el._id + index"
      v-for="(el, index) in realValue"
    >
      <BaseCard padding="0">
        <CollapsibleContentLight
          v-bind:title="getTitle(el)"
          v-bind:collapseId="el._id"
          v-bind:button-bg-color="`${colorsHtml.background}`"
          v-bind:button-padding="'7px 15px'"
          v-bind:content-padding="'15px'"
          v-bind:key="loopKey + el._id"
          v-bind:is-open="openItems.includes(el._id)"
          v-bind:toggle-open-item="openItemsFn"
        >
          <div class="item-content">
            <FormulateInput
              type="dropDown"
              class="product-create__select"
              name="classification"
              validation="required"
              v-model="el.url"
              v-bind:activeInput="true"
              v-bind:parentBlockId="el._id"
              v-bind:disabled="disabled ? disabled : dropDownObject.disabled"
              v-bind:dataArray="dropDownObject.data"
              v-bind:getList="dropDownObject.getList"
              v-bind:title="'Select page or type url'"
              v-bind:selected="el.url"
              v-bind:searchAction="dropDownObject.dropdownSearch"
              @dropdown-item-changed="dropDownObject.dropdownChanged"
            />
            <FormulateInput
              type="text"
              name="text"
              label="Text"
              v-model="el.title"
              error-behavior="live"
              :disabled="disabled"
              v-bind:class="
                Object.assign(
                  { 'customize-label': true, active: el.title },
                  editableClass
                )
              "
              @focus="onFocus"
              @blur="onBlur"
            />
            <FormulateInput
              type="text"
              name="text"
              label="Description"
              :disabled="disabled"
              v-model="el.description"
              error-behavior="live"
              v-bind:class="
                Object.assign(
                  { 'customize-label': true, active: el.description },
                  editableClass
                )
              "
              @focus="onFocus"
              @blur="onBlur"
            />
            <FormulateInput
              type="checkbox"
              name="text"
              label="Additional flag"
              :disabled="disabled"
              v-model="el.mobile_tab"
              error-behavior="live"
              v-bind:class="
                Object.assign(
                  {
                    'customize-label': true,
                    'additional-flag': true,
                    active: el.mobile_tab,
                  },
                  editableClass
                )
              "
              @focus="onFocus"
              @blur="onBlur"
            />
            <ImageInput
              v-bind:label="'Image'"
              v-bind:value="el.image"
              v-bind:editable="!disabled"
              v-bind:url="typeof el.image === 'string' ? el.image : ''"
              v-bind:name="el._id"
              v-on:image-changed="getFile($event, el._id)"
            />
            <Button
              class="ml-auto mr-0 d-block item-group__btn"
              variant="danger"
              v-on:click="deleteAction(el._id, realValue)"
              v-if="!disabled"
              label="Delete"
              :bg-color="`${colorsHtml.danger}`"
              font-weight="700"
            />
          </div>
        </CollapsibleContentLight>
      </BaseCard>

      <NestedDraggable
        class="item-sub"
        v-bind:list="el.children"
        v-bind:delete-action="deleteAction"
        v-bind:drop-down-object="dropDownObject"
        v-bind:key="loopKey"
        v-bind:loop-key="loopKey"
        v-bind:open-items="openItems"
        v-bind:open-items-fn="openItemsFn"
        v-bind:disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button";
import CollapsibleContentLight from "@/components/Collapsible/CollapsibleContentLight";
import BaseCard from "@/components/Cards/BaseCard";
import ImageInput from "@/components/Input/ImageInput";
import colorsHtml from "/colors.config.json";
export default {
  name: "NestedDraggable",
  components: {
    Button,
    CollapsibleContentLight,
    BaseCard,
    ImageInput,
  },
  props: {
    value: {
      required: false,
      type: Array,
      default: null,
    },
    list: {
      required: false,
      type: Array,
      default: null,
    },
    deleteAction: {
      type: Function,
      default: () => [],
    },
    dropDownObject: {
      type: Object,
      default: () => [],
    },
    loopKey: {
      type: [String, Number],
      default: Date.now(),
    },
    openItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    openItemsFn: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colorsHtml: colorsHtml,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    realValue() {
      return this.value ? this.value : this.list;
    },
    editableClass() {
      if (this.disabled) {
        return { "not-editable": true };
      }
      return {};
    },
  },
  methods: {
    getTitle(el) {
      if (el.title && el.title.length > 0) return String(el.title);

      return "<untitled>";
    },
    onFocus(e) {
      e.target.closest(".customize-label").classList.add("active");
    },
    onBlur(e) {
      if (!e.target.value) {
        e.target.closest(".customize-label").classList.remove("active");
      }
    },
    findRecursively(value, id, list) {
      list.forEach((el) => {
        if (el._id === id) {
          el.image = value;
        } else if (el.children.length > 0) {
          this.findRecursively(value, id, el.children);
        }
      });
    },
    getFile(value, id) {
      if (typeof value.name === "undefined") {
        this.findRecursively("", id, this.realValue);
      } else {
        this.findRecursively(value, id, this.realValue);
      }
    },
    emitter(value) {
      if (this.disabled) return;

      this.$root.$emit("dragAndDropMenu", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/multi-component/formulate-label";
@import "@/styles/variables";
.item-container {
  margin: 0;

  .item-group {
    padding-top: 30px;

    &:first-child {
      padding-top: 0;
    }

    .item {
      padding: 1rem;
      border: solid black 1px;
      background-color: #fefefe;
    }

    .item-sub {
      margin: 0 0 0 2rem;

      .item-group {
        padding-top: 30px;
      }
    }

    &__btn {
      margin-top: 15px;
    }
  }
}
.ghost {
  opacity: 0.5;

  .jumbotron.base-card {
    border: 1px dashed $black;
  }
}

::v-deep {
  .formulate-input-element {
    max-width: 100%;
  }
  .additional-flag {
    border: 1px solid $lightgray;
    padding: 9px;
    display: flex;
    align-items: center;
    border-radius: 4px;
  }
  .additional-flag
    .formulate-input-element.formulate-input-element--checkbox
    label {
    margin-bottom: 0;
  }
}
</style>
