<template>
	<BaseCard class="x-form-actions">

		<Dropdown
			:list="data.dropdownList"
			placeholder="Select an option"
			@changed="valueChanged"
		/>

		<div class="x-form-actions__btns">
			<Button
				:key="idx"
				v-for="( button, idx ) in data.btns"
				:label="button.label"
				:variant="button.variant"
				:action="button.action"
			/>
		</div>

	</BaseCard>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard";
import Dropdown from "@/components/Dropdowns/Dropdown";
import Button from "@/components/Buttons/Button.vue";

export default {
	name: 'XFormActions',
	components: {
		BaseCard,
		Dropdown,
		Button,
	},
	props: {
		data: {
			type: Object,
			default: () => {}
		}
	},
	methods: {
		valueChanged( value ) {
			this.$emit( 'changed', value )
		},
	},
}
</script>

<style lang='scss' scoped>

.x-form-actions {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 25px;

	&__btns {
		button {
			padding: 7px 20px;
			margin-left: 10px;
		}
	}
}

</style>