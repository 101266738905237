<template>
  <div
    v-if="admin_roles !== false && this.isAllowed"
    class="entities view content-padding"
  >
    <div class="entities__header header">
      <HeaderLabel
        :label-buttons="labelButtons"
        :buttons-list="buttonsList"
        :separate-buttons="separateButtons"
        :title="`${labels.menu_title}: `"
        :entity-label="menuTitle"
      />
    </div>
    <div class="entities__content content__body content">
      <div cols="12" xl="8" class="content__main body-main">
        <template v-if="showXActions">
          <BaseCard
            padding="10px 25px 30px"
            margin="0 0 30px"
            :class="'xform-actions ' + xFormStatus"
            overflow="visible"
          >
            <XFormActions
              :xFormStatus="xFormStatus"
              :valueKey="'body'"
              :xActionsObj="xActionsObj"
              :key="xActionsUpdate"
            />
          </BaseCard>
        </template>
        <BaseCard padding="25px">
          <NestedDraggable
            v-model="menuList"
            v-bind:delete-action="deleteMenuItem"
            v-bind:drop-down-object="dropDownObject"
            v-bind:key="xActionsObj.loopKey"
            v-bind:loop-key="xActionsObj.loopKey"
            v-bind:open-items="openMenuItems"
            v-bind:open-items-fn="toggleMenuCollapsible"
            v-bind:disabled="false"
          />
          <div class="drop-area" v-on:click="addMenuItem">
            <div class="drop-area__line"></div>
            <CircleButton icon="plus" :bg-color="`${colorsHtml.navy}`" />
          </div>
        </BaseCard>
      </div>
    </div>
    <router-view :name="`single_${entityType}_notes`"></router-view>
  </div>
</template>

<script>
import HeaderLabel from "@/components/Labels/HeaderLabel";
import BaseCard from "@/components/Cards/BaseCard";
import NestedDraggable from "@/components/Blocks/Menu/NestedDraggable";
import CircleButton from "@/components/Buttons/CircleButton";
import XFormActions from "@/components/Forms/XFormActions";

import prepareForm from "@/services/prepare-formdata";
import { debounce } from "@/services/debounce";

import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import colorsHtml from "/colors.config.json";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBook,
  faCog,
  faTrash,
  faCheck,
  faSignInAlt,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
library.add(faBook, faCog, faTrash, faCheck, faSignInAlt, faPlus, faTimes);

export default {
  components: {
    HeaderLabel,
    BaseCard,
    NestedDraggable,
    CircleButton,
    XFormActions,
  },
  data() {
    return {
      colorsHtml: colorsHtml,
      loadedData: false,
      xActionsUpdate: Date.now(),
      originalSingleEntity: {},
      menuTitle: "",
      saveBtnDisabled: false,
      menuList: [],
      dropDownObject: {
        disabled: false,
        data: [],
        timeout: null,
        dropdownChanged: this.dropdownChanged,
        dropdownSearch: this.dropdownSearchFN,
        getList: this.getPagesListFN,
      },
      showXActions: false,
      xFormStatus: "",
    };
  },
  props: {
    entityType: {
      type: String,
      default: "menu",
    },
  },
  computed: {
    ...mapState("helper", {
      admin_roles: "admin_roles",
    }),
    ...mapState("menu", {
      singleEntity: "singleEntity",
      openMenuItems: "openMenuItems",
    }),
    ...mapGetters("helper", {
      hasPermissions: "hasPermissions",
    }),
    ...mapGetters("helper", ["labels"]),
    isAllowed() {
      return this.hasPermissions(["super", "web"]);
    },
    xActionsObj() {
      return {};
    },
    id() {
      return this.$route.params.id;
    },
    separateButtons() {
      return [
        {
          label: this.labels[`exit_${this.xFormStatus}_mode`],
          icon: "",
          show: this.showXActions,
          action: this.goToCurrent,
          disabled: false,
          variant: "outline-warning",
          titleAttribute: this.labels[`exit_${this.xFormStatus}_mode`],
        },
      ];
    },
    labelButtons() {
      return [
        {
          label: this.labels.menu,
          icon: "",
          show: true,
          action: () => {},
          disabled: false,
          class: "current",
          titleAttribute: this.labels.menu,
        },
      ];
    },
    buttonsList() {
      return [
        {
          label: this.labels.save,
          icon: "",
          variant: "outline-secondary",
          show: true,
          action: this.editEntity,
          disabled: false,
        },
        {
          label: "",
          icon: "cog",
          variant: "outline-secondary",
          show: true,
          action: () => {
            this.$router.push({
              name: "single_menu_settings",
              params: {
                id: this.$route.params.id,
              },
            });
          },
          disabled: false,
          titleAttribute: this.labels.general_settings,
        },
      ];
    },
    lastModifyTime() {
      return "22-06-2021 10:22";
    },
    createTime() {
      return "22-06-2021 10:22";
    },
  },
  methods: {
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("page", {
      getPagesList: "getPagesList",
    }),
    ...mapActions("values", {
      getValue: "getValue",
      approveValue: "approveValue",
      rejectValue: "rejectValue",
      saveValue: "saveValue",
      reinstateValue: "reinstateValue",
    }),
    ...mapMutations("menu", {
      setEntityPropValue: "setEntityPropValue",
      toggleMenuCollapsible: "toggleMenuCollapsible",
      deleteRevision: "deleteRevision",
    }),
    getEntityForEditor(payload) {
      let upperCaseFirst =
        this.entityType[0].toUpperCase() +
        this.entityType.slice(1, this.entityType.length);
      return this.$store.dispatch(
        `${this.entityType}/get${upperCaseFirst}ForEditor`,
        payload
      );
    },

    updateEntity(payload) {
      let upperCaseFirst =
        this.entityType[0].toUpperCase() +
        this.entityType.slice(1, this.entityType.length);
      return this.$store.dispatch(
        `${this.entityType}/update${upperCaseFirst}`,
        payload
      );
    },

    objectToArrayRecursively(menuList) {
      menuList.forEach((item) => {
        if (item.children && Object.values(item.children).length > 0) {
          item.children = Object.values(item.children);
          this.objectToArrayRecursively(item.children);
        } else {
          item.children = [];
        }
      });
    },

    getSingleEntityWrapper(payload, callback) {
      let self = this;

      this.getEntityForEditor(payload)
        .then((res) => {
          self.menuTitle = res.data.title;
          self.menuList = Object.values(
            JSON.parse(JSON.stringify(res.data.body))
          );
          self.objectToArrayRecursively(self.menuList);
          self.originalSingleEntity = JSON.parse(JSON.stringify(res.data));

          if (typeof callback === "function") callback(res);
        })
        .catch((err) => console.log(err));
    },

    // sends updated object to server in order to save it (wrapper for state action)
    editEntity() {
      let payload = {};
      payload.body = this.menuList;

      let self = this;

      this.updateEntity(
        prepareForm({ payload: payload, id: self.$route.params.id })
      ).then((res) => {
        if (res.success) {
          self.addNotification({
            variant: "success",
            msg: this.labels.object_has_been_updated,
          });
          // query updated entity from database in order to refresh the fields
          self.getSingleEntityWrapper(
            { id: self.$route.params.id },
            () => (self.loadedData = true)
          );
        } else if (res.error) {
          self.addNotification({
            variant: "danger",
            msg: res.error,
            labels: self.labels,
          });
        }
      });
    },

    makeId() {
      let str = "id_";
      return (
        str +
        "xxxxxxxx_xxxx_4xxx_yxxx_xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        })
      );
    },

    addMenuItem() {
      this.menuList.push({
        children: [],
        _id: this.makeId(),
        url: "/",
        description: "",
        title: this.labels.menu_item,
        image: "",
      });
      let singleMenu = JSON.parse(JSON.stringify(this.singleEntity));
      singleMenu.body = JSON.parse(JSON.stringify(this.menuList));
      this.$store.commit("menu/getMenuForEditor", singleMenu);
    },

    deleteMenuItem(id, arrayList) {
      arrayList.forEach((item, idx) => {
        if (item._id === id) {
          let children = [];
          if (item.children.length > 0) children = item.children;

          let end = arrayList.slice(idx + 1);
          arrayList.splice(idx);

          arrayList.push(...children);
          arrayList.push(...end);
        } else if (item.children.length > 0) {
          this.deleteMenuItem(id, item.children);
        }
      });
    },

    findRecursively(value, id, list) {
      list.forEach((el) => {
        if (el._id === id) {
          if (value?.field && value[value.field])
            this.$set(el, "url", value[value.field]);
          else if (value && value.permalink)
            this.$set(el, "url", value.permalink);
        } else if (el.children.length > 0) {
          this.findRecursively(value, id, el.children);
        }
      });
    },

    // Method to listen changes in the <FormulateInput type="dropDown" />
    dropdownChanged(dropDownItem) {
      this.findRecursively(
        dropDownItem,
        dropDownItem.parent_block_id,
        this.menuList
      );
    },

    // Method for searching item in the <FormulateInput type="dropDown" />
    dropdownSearchFN(search, e, key) {
      if (e.keyCode && e.keyCode !== 13) {
        clearTimeout(this.dropDownObject.timeout);

        let self = this;

        this.dropDownObject.timeout = setTimeout(function () {
          self.getPagesListFN({ page: 1, key }, search);
        }, 1000);
      } else {
        clearTimeout(this.dropDownObject.timeout);

        this.getPagesListFN({ page: 1, key }, search);
      }
    },

    // Method to obtain list of items for <FormulateInput type="dropDown" />
    getPagesListFN(settings, searchTerm) {
      let data = { page: settings.page };

      if (searchTerm) data.searchTerm = searchTerm;

      let self = this;
      let res = this.getPagesList(data)
        .then((res) => {
          if (res.success) {
            let resDataArray = [...res.data.data];

            let newData = [];

            if (settings.page > 1) {
              newData = self.dropDownObject.data.concat(resDataArray);
            } else {
              newData = resDataArray;
            }
            let arr = [];

            newData.forEach((item) => {
              let clone = { ...item };
              if (clone.permalink) {
                clone.title = `${clone.title} ( ${clone.permalink} )`;
              }
              arr.push(clone);
            });
            self.dropDownObject.data = arr;

            if (resDataArray.length < 20) {
              self.dropDownObject.disabled = true;
            }

            return res;
          }
        })
        .catch((err) => console.log(err));

      return res;
    },

    currentUserCan(action) {
      if (
        this.singleEntity &&
        this.singleEntity.permissions &&
        this.singleEntity.permissions[action] &&
        this.singleEntity.permissions[action] == 1
      ) {
        return true;
      }
      return false;
    },
  },

  mounted() {
    let self = this;

    this.getSingleEntityWrapper({ id: this.$route.params.id }, () => {
      self.loadedData = true;
    });
  },

  watch: {
    menuList: {
      deep: true,
      handler() {
        let singleMenu = JSON.parse(JSON.stringify(this.singleEntity));
        // singleMenu.body = JSON.parse( JSON.stringify( this.menuList ) );

        debounce(
          () => {
            this.$store.commit("menu/getMenuForEditor", singleMenu);
          },
          500,
          singleMenu.title
            ? JSON.stringify(singleMenu.title)
            : "update-single-menu"
        );
      },
    },
    admin_roles(newValue, oldValue) {
      if (oldValue === false && !this.isAllowed)
        this.$router.push({ name: "dashboard" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.body-secondary,
.body-main,
.body-sidebar,
.entities__content {
  --actionPanelWidth: 362px;
  --blockListWidth: 340px;
}

@media screen and (max-width: 1600px) {
  .body-secondary,
  .body-main,
  .body-sidebar,
  .entities__content {
    --actionPanelWidth: 362px;
    --blockListWidth: 300px;
  }
}

@media screen and (max-width: 1400px) {
  .body-secondary,
  .body-main,
  .body-sidebar,
  .entities__content {
    --actionPanelWidth: 362px;
    --blockListWidth: 260px;
  }
}

.entities {
  &__content {
    .content {
      &__main,
      .body-main {
        width: calc(100% - var(--actionPanelWidth));
        max-width: calc(100% - var(--actionPanelWidth));
        flex: 0 1 calc(100% - var(--actionPanelWidth));

        @media screen and (max-width: 1279px) {
          flex: 0 1 100%;
          max-width: 100%;
        }

        .xform-actions {
          margin-top: 0 !important;

          &::before {
            display: none;
          }
        }

        .drop-area {
          transition: 0.3s;
          position: relative;
          padding-bottom: 10px;
          padding-top: 10px;
          margin-top: 30px;
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }

          &__line {
            height: 4px;
            background-color: $light_gray;
          }

          .add-button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            ::v-deep a:hover {
              opacity: 1 !important;
            }
          }
        }
      }

      &__sidebar,
      .body-sidebar {
        flex: 0 1 var(--actionPanelWidth);
        max-width: var(--actionPanelWidth);
        position: sticky;
        top: 70px;
        height: calc(100vh - 152px);
        overflow: auto;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;

        @media screen and (max-width: 1279px) {
          flex: 0 1 100%;
          max-width: 100%;
        }
        @media (min-width: 1200px) {
          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            background: silver;
          }

          &::-webkit-scrollbar-thumb {
            background-color: gray;
          }
        }
      }
    }
  }
}

::v-deep {
  .mr-2,
  .mx-2 {
    margin-right: 0 !important;
  }

  .card-arrow svg {
    margin-left: 0;
  }

  .card-arrow {
    position: absolute;
    top: -6px;
    left: 50%;
  }

  .content__main {
    min-width: 100%;
  }
}
</style>
